type Timezones = {
   ariaLabel: string;
   formattedText: string;
   timeZone: string;
}

export const timezones  = (): Timezones[]  => {
    return [
        {
           "timeZone":"Pacific/Niue",
           "ariaLabel":"(GMT minus 11:00)Niue Time",
           "formattedText":"(GMT-11:00) Niue Time"
        },
        {
           "timeZone":"Pacific/Pago_Pago",
           "ariaLabel":"(GMT minus 11:00)Samoa Standard Time",
           "formattedText":"(GMT-11:00) Samoa Standard Time"
        },
        {
           "timeZone":"Pacific/Rarotonga",
           "ariaLabel":"(GMT minus 10:00)Cook Islands Standard Time",
           "formattedText":"(GMT-10:00) Cook Islands Standard Time"
        },
        {
           "timeZone":"Pacific/Honolulu",
           "ariaLabel":"(GMT minus 10:00)Hawaii-Aleutian Standard Time",
           "formattedText":"(GMT-10:00) Hawaii-Aleutian Standard Time"
        },
        {
           "timeZone":"Pacific/Tahiti",
           "ariaLabel":"(GMT minus 10:00)Tahiti Time",
           "formattedText":"(GMT-10:00) Tahiti Time"
        },
        {
           "timeZone":"Pacific/Marquesas",
           "ariaLabel":"(GMT minus 09:30)Marquesas Time",
           "formattedText":"(GMT-09:30) Marquesas Time"
        },
        {
           "timeZone":"Pacific/Gambier",
           "ariaLabel":"(GMT minus 09:00)Gambier Time",
           "formattedText":"(GMT-09:00) Gambier Time"
        },
        {
           "timeZone":"America/Adak",
           "ariaLabel":"(GMT minus 09:00)Hawaii-Aleutian Time (Adak)",
           "formattedText":"(GMT-09:00) Hawaii-Aleutian Time (Adak)"
        },
        {
           "timeZone":"America/Anchorage",
           "ariaLabel":"(GMT minus 08:00)Alaska Time - Anchorage",
           "formattedText":"(GMT-08:00) Alaska Time - Anchorage"
        },
        {
           "timeZone":"America/Juneau",
           "ariaLabel":"(GMT minus 08:00)Alaska Time - Juneau",
           "formattedText":"(GMT-08:00) Alaska Time - Juneau"
        },
        {
           "timeZone":"America/Metlakatla",
           "ariaLabel":"(GMT minus 08:00)Alaska Time - Metlakatla",
           "formattedText":"(GMT-08:00) Alaska Time - Metlakatla"
        },
        {
           "timeZone":"America/Nome",
           "ariaLabel":"(GMT minus 08:00)Alaska Time - Nome",
           "formattedText":"(GMT-08:00) Alaska Time - Nome"
        },
        {
           "timeZone":"America/Sitka",
           "ariaLabel":"(GMT minus 08:00)Alaska Time - Sitka",
           "formattedText":"(GMT-08:00) Alaska Time - Sitka"
        },
        {
           "timeZone":"America/Yakutat",
           "ariaLabel":"(GMT minus 08:00)Alaska Time - Yakutat",
           "formattedText":"(GMT-08:00) Alaska Time - Yakutat"
        },
        {
           "timeZone":"Pacific/Pitcairn",
           "ariaLabel":"(GMT minus 08:00)Pitcairn Time",
           "formattedText":"(GMT-08:00) Pitcairn Time"
        },
        {
           "timeZone":"America/Hermosillo",
           "ariaLabel":"(GMT minus 07:00)Mexican Pacific Standard Time - Hermosillo",
           "formattedText":"(GMT-07:00) Mexican Pacific Standard Time - Hermosillo"
        },
        {
           "timeZone":"America/Mazatlan",
           "ariaLabel":"(GMT minus 07:00)Mexican Pacific Standard Time - Mazatlan",
           "formattedText":"(GMT-07:00) Mexican Pacific Standard Time - Mazatlan"
        },
        {
           "timeZone":"America/Dawson_Creek",
           "ariaLabel":"(GMT minus 07:00)Mountain Standard Time - Dawson Creek",
           "formattedText":"(GMT-07:00) Mountain Standard Time - Dawson Creek"
        },
        {
           "timeZone":"America/Fort_Nelson",
           "ariaLabel":"(GMT minus 07:00)Mountain Standard Time - Fort Nelson",
           "formattedText":"(GMT-07:00) Mountain Standard Time - Fort Nelson"
        },
        {
           "timeZone":"America/Phoenix",
           "ariaLabel":"(GMT minus 07:00)Mountain Standard Time - Phoenix",
           "formattedText":"(GMT-07:00) Mountain Standard Time - Phoenix"
        },
        {
           "timeZone":"America/Los_Angeles",
           "ariaLabel":"(GMT minus 07:00)Pacific Time - Los Angeles",
           "formattedText":"(GMT-07:00) Pacific Time - Los Angeles"
        },
        {
           "timeZone":"America/Tijuana",
           "ariaLabel":"(GMT minus 07:00)Pacific Time - Tijuana",
           "formattedText":"(GMT-07:00) Pacific Time - Tijuana"
        },
        {
           "timeZone":"America/Vancouver",
           "ariaLabel":"(GMT minus 07:00)Pacific Time - Vancouver",
           "formattedText":"(GMT-07:00) Pacific Time - Vancouver"
        },
        {
           "timeZone":"America/Dawson",
           "ariaLabel":"(GMT minus 07:00)Yukon Time - Dawson",
           "formattedText":"(GMT-07:00) Yukon Time - Dawson"
        },
        {
           "timeZone":"America/Whitehorse",
           "ariaLabel":"(GMT minus 07:00)Yukon Time - Whitehorse",
           "formattedText":"(GMT-07:00) Yukon Time - Whitehorse"
        },
        {
           "timeZone":"America/Bahia_Banderas",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Bahía de Banderas",
           "formattedText":"(GMT-06:00) Central Standard Time - Bahía de Banderas"
        },
        {
           "timeZone":"America/Belize",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Belize",
           "formattedText":"(GMT-06:00) Central Standard Time - Belize"
        },
        {
           "timeZone":"America/Chihuahua",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Chihuahua",
           "formattedText":"(GMT-06:00) Central Standard Time - Chihuahua"
        },
        {
           "timeZone":"America/Costa_Rica",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Costa Rica",
           "formattedText":"(GMT-06:00) Central Standard Time - Costa Rica"
        },
        {
           "timeZone":"America/El_Salvador",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - El Salvador",
           "formattedText":"(GMT-06:00) Central Standard Time - El Salvador"
        },
        {
           "timeZone":"America/Guatemala",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Guatemala",
           "formattedText":"(GMT-06:00) Central Standard Time - Guatemala"
        },
        {
           "timeZone":"America/Managua",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Managua",
           "formattedText":"(GMT-06:00) Central Standard Time - Managua"
        },
        {
           "timeZone":"America/Merida",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Mérida",
           "formattedText":"(GMT-06:00) Central Standard Time - Mérida"
        },
        {
           "timeZone":"America/Mexico_City",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Mexico City",
           "formattedText":"(GMT-06:00) Central Standard Time - Mexico City"
        },
        {
           "timeZone":"America/Monterrey",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Monterrey",
           "formattedText":"(GMT-06:00) Central Standard Time - Monterrey"
        },
        {
           "timeZone":"America/Regina",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Regina",
           "formattedText":"(GMT-06:00) Central Standard Time - Regina"
        },
        {
           "timeZone":"America/Swift_Current",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Swift Current",
           "formattedText":"(GMT-06:00) Central Standard Time - Swift Current"
        },
        {
           "timeZone":"America/Tegucigalpa",
           "ariaLabel":"(GMT minus 06:00)Central Standard Time - Tegucigalpa",
           "formattedText":"(GMT-06:00) Central Standard Time - Tegucigalpa"
        },
        {
           "timeZone":"Pacific/Easter",
           "ariaLabel":"(GMT minus 06:00)Easter Island Time",
           "formattedText":"(GMT-06:00) Easter Island Time"
        },
        {
           "timeZone":"Pacific/Galapagos",
           "ariaLabel":"(GMT minus 06:00)Galapagos Time",
           "formattedText":"(GMT-06:00) Galapagos Time"
        },
        {
           "timeZone":"America/Boise",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Boise",
           "formattedText":"(GMT-06:00) Mountain Time - Boise"
        },
        {
           "timeZone":"America/Cambridge_Bay",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Cambridge Bay",
           "formattedText":"(GMT-06:00) Mountain Time - Cambridge Bay"
        },
        {
           "timeZone":"America/Ciudad_Juarez",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Ciudad Juárez",
           "formattedText":"(GMT-06:00) Mountain Time - Ciudad Juárez"
        },
        {
           "timeZone":"America/Denver",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Denver",
           "formattedText":"(GMT-06:00) Mountain Time - Denver"
        },
        {
           "timeZone":"America/Edmonton",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Edmonton",
           "formattedText":"(GMT-06:00) Mountain Time - Edmonton"
        },
        {
           "timeZone":"America/Inuvik",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Inuvik",
           "formattedText":"(GMT-06:00) Mountain Time - Inuvik"
        },
        {
           "timeZone":"America/Yellowknife",
           "ariaLabel":"(GMT minus 06:00)Mountain Time - Yellowknife",
           "formattedText":"(GMT-06:00) Mountain Time - Yellowknife"
        },
        {
           "timeZone":"America/Eirunepe",
           "ariaLabel":"(GMT minus 05:00)Acre Standard Time - Eirunepe",
           "formattedText":"(GMT-05:00) Acre Standard Time - Eirunepe"
        },
        {
           "timeZone":"America/Rio_Branco",
           "ariaLabel":"(GMT minus 05:00)Acre Standard Time - Rio Branco",
           "formattedText":"(GMT-05:00) Acre Standard Time - Rio Branco"
        },
        {
           "timeZone":"America/North_Dakota/Beulah",
           "ariaLabel":"(GMT minus 05:00)Central Time - Beulah, North Dakota",
           "formattedText":"(GMT-05:00) Central Time - Beulah, North Dakota"
        },
        {
           "timeZone":"America/North_Dakota/Center",
           "ariaLabel":"(GMT minus 05:00)Central Time - Center, North Dakota",
           "formattedText":"(GMT-05:00) Central Time - Center, North Dakota"
        },
        {
           "timeZone":"America/Chicago",
           "ariaLabel":"(GMT minus 05:00)Central Time - Chicago",
           "formattedText":"(GMT-05:00) Central Time - Chicago"
        },
        {
           "timeZone":"America/Indiana/Knox",
           "ariaLabel":"(GMT minus 05:00)Central Time - Knox, Indiana",
           "formattedText":"(GMT-05:00) Central Time - Knox, Indiana"
        },
        {
           "timeZone":"America/Matamoros",
           "ariaLabel":"(GMT minus 05:00)Central Time - Matamoros",
           "formattedText":"(GMT-05:00) Central Time - Matamoros"
        },
        {
           "timeZone":"America/Menominee",
           "ariaLabel":"(GMT minus 05:00)Central Time - Menominee",
           "formattedText":"(GMT-05:00) Central Time - Menominee"
        },
        {
           "timeZone":"America/North_Dakota/New_Salem",
           "ariaLabel":"(GMT minus 05:00)Central Time - New Salem, North Dakota",
           "formattedText":"(GMT-05:00) Central Time - New Salem, North Dakota"
        },
        {
           "timeZone":"America/Ojinaga",
           "ariaLabel":"(GMT minus 05:00)Central Time - Ojinaga",
           "formattedText":"(GMT-05:00) Central Time - Ojinaga"
        },
        {
           "timeZone":"America/Rainy_River",
           "ariaLabel":"(GMT minus 05:00)Central Time - Rainy River",
           "formattedText":"(GMT-05:00) Central Time - Rainy River"
        },
        {
           "timeZone":"America/Rankin_Inlet",
           "ariaLabel":"(GMT minus 05:00)Central Time - Rankin Inlet",
           "formattedText":"(GMT-05:00) Central Time - Rankin Inlet"
        },
        {
           "timeZone":"America/Resolute",
           "ariaLabel":"(GMT minus 05:00)Central Time - Resolute",
           "formattedText":"(GMT-05:00) Central Time - Resolute"
        },
        {
           "timeZone":"America/Indiana/Tell_City",
           "ariaLabel":"(GMT minus 05:00)Central Time - Tell City, Indiana",
           "formattedText":"(GMT-05:00) Central Time - Tell City, Indiana"
        },
        {
           "timeZone":"America/Winnipeg",
           "ariaLabel":"(GMT minus 05:00)Central Time - Winnipeg",
           "formattedText":"(GMT-05:00) Central Time - Winnipeg"
        },
        {
           "timeZone":"America/Bogota",
           "ariaLabel":"(GMT minus 05:00)Colombia Standard Time",
           "formattedText":"(GMT-05:00) Colombia Standard Time"
        },
        {
           "timeZone":"America/Cancun",
           "ariaLabel":"(GMT minus 05:00)Eastern Standard Time - Cancún",
           "formattedText":"(GMT-05:00) Eastern Standard Time - Cancún"
        },
        {
           "timeZone":"America/Jamaica",
           "ariaLabel":"(GMT minus 05:00)Eastern Standard Time - Jamaica",
           "formattedText":"(GMT-05:00) Eastern Standard Time - Jamaica"
        },
        {
           "timeZone":"America/Panama",
           "ariaLabel":"(GMT minus 05:00)Eastern Standard Time - Panama",
           "formattedText":"(GMT-05:00) Eastern Standard Time - Panama"
        },
        {
           "timeZone":"America/Guayaquil",
           "ariaLabel":"(GMT minus 05:00)Ecuador Time",
           "formattedText":"(GMT-05:00) Ecuador Time"
        },
        {
           "timeZone":"America/Lima",
           "ariaLabel":"(GMT minus 05:00)Peru Standard Time",
           "formattedText":"(GMT-05:00) Peru Standard Time"
        },
        {
           "timeZone":"America/Boa_Vista",
           "ariaLabel":"(GMT minus 04:00)Amazon Standard Time - Boa Vista",
           "formattedText":"(GMT-04:00) Amazon Standard Time - Boa Vista"
        },
        {
           "timeZone":"America/Campo_Grande",
           "ariaLabel":"(GMT minus 04:00)Amazon Standard Time - Campo Grande",
           "formattedText":"(GMT-04:00) Amazon Standard Time - Campo Grande"
        },
        {
           "timeZone":"America/Cuiaba",
           "ariaLabel":"(GMT minus 04:00)Amazon Standard Time - Cuiaba",
           "formattedText":"(GMT-04:00) Amazon Standard Time - Cuiaba"
        },
        {
           "timeZone":"America/Manaus",
           "ariaLabel":"(GMT minus 04:00)Amazon Standard Time - Manaus",
           "formattedText":"(GMT-04:00) Amazon Standard Time - Manaus"
        },
        {
           "timeZone":"America/Porto_Velho",
           "ariaLabel":"(GMT minus 04:00)Amazon Standard Time - Porto Velho",
           "formattedText":"(GMT-04:00) Amazon Standard Time - Porto Velho"
        },
        {
           "timeZone":"America/Barbados",
           "ariaLabel":"(GMT minus 04:00)Atlantic Standard Time - Barbados",
           "formattedText":"(GMT-04:00) Atlantic Standard Time - Barbados"
        },
        {
           "timeZone":"America/Martinique",
           "ariaLabel":"(GMT minus 04:00)Atlantic Standard Time - Martinique",
           "formattedText":"(GMT-04:00) Atlantic Standard Time - Martinique"
        },
        {
           "timeZone":"America/Puerto_Rico",
           "ariaLabel":"(GMT minus 04:00)Atlantic Standard Time - Puerto Rico",
           "formattedText":"(GMT-04:00) Atlantic Standard Time - Puerto Rico"
        },
        {
           "timeZone":"America/Santo_Domingo",
           "ariaLabel":"(GMT minus 04:00)Atlantic Standard Time - Santo Domingo",
           "formattedText":"(GMT-04:00) Atlantic Standard Time - Santo Domingo"
        },
        {
           "timeZone":"America/La_Paz",
           "ariaLabel":"(GMT minus 04:00)Bolivia Time",
           "formattedText":"(GMT-04:00) Bolivia Time"
        },
        {
           "timeZone":"America/Santiago",
           "ariaLabel":"(GMT minus 04:00)Chile Time",
           "formattedText":"(GMT-04:00) Chile Time"
        },
        {
           "timeZone":"America/Havana",
           "ariaLabel":"(GMT minus 04:00)Cuba Time",
           "formattedText":"(GMT-04:00) Cuba Time"
        },
        {
           "timeZone":"America/Detroit",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Detroit",
           "formattedText":"(GMT-04:00) Eastern Time - Detroit"
        },
        {
           "timeZone":"America/Grand_Turk",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Grand Turk",
           "formattedText":"(GMT-04:00) Eastern Time - Grand Turk"
        },
        {
           "timeZone":"America/Indiana/Indianapolis",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Indianapolis",
           "formattedText":"(GMT-04:00) Eastern Time - Indianapolis"
        },
        {
           "timeZone":"America/Iqaluit",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Iqaluit",
           "formattedText":"(GMT-04:00) Eastern Time - Iqaluit"
        },
        {
           "timeZone":"America/Kentucky/Louisville",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Louisville",
           "formattedText":"(GMT-04:00) Eastern Time - Louisville"
        },
        {
           "timeZone":"America/Indiana/Marengo",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Marengo, Indiana",
           "formattedText":"(GMT-04:00) Eastern Time - Marengo, Indiana"
        },
        {
           "timeZone":"America/Kentucky/Monticello",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Monticello, Kentucky",
           "formattedText":"(GMT-04:00) Eastern Time - Monticello, Kentucky"
        },
        {
           "timeZone":"America/New_York",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - New York",
           "formattedText":"(GMT-04:00) Eastern Time - New York"
        },
        {
           "timeZone":"America/Nipigon",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Nipigon",
           "formattedText":"(GMT-04:00) Eastern Time - Nipigon"
        },
        {
           "timeZone":"America/Pangnirtung",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Pangnirtung",
           "formattedText":"(GMT-04:00) Eastern Time - Pangnirtung"
        },
        {
           "timeZone":"America/Indiana/Petersburg",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Petersburg, Indiana",
           "formattedText":"(GMT-04:00) Eastern Time - Petersburg, Indiana"
        },
        {
           "timeZone":"America/Port-au-Prince",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Port-au-Prince",
           "formattedText":"(GMT-04:00) Eastern Time - Port-au-Prince"
        },
        {
           "timeZone":"America/Thunder_Bay",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Thunder Bay",
           "formattedText":"(GMT-04:00) Eastern Time - Thunder Bay"
        },
        {
           "timeZone":"America/Toronto",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Toronto",
           "formattedText":"(GMT-04:00) Eastern Time - Toronto"
        },
        {
           "timeZone":"America/Indiana/Vevay",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Vevay, Indiana",
           "formattedText":"(GMT-04:00) Eastern Time - Vevay, Indiana"
        },
        {
           "timeZone":"America/Indiana/Vincennes",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Vincennes, Indiana",
           "formattedText":"(GMT-04:00) Eastern Time - Vincennes, Indiana"
        },
        {
           "timeZone":"America/Indiana/Winamac",
           "ariaLabel":"(GMT minus 04:00)Eastern Time - Winamac, Indiana",
           "formattedText":"(GMT-04:00) Eastern Time - Winamac, Indiana"
        },
        {
           "timeZone":"America/Guyana",
           "ariaLabel":"(GMT minus 04:00)Guyana Time",
           "formattedText":"(GMT-04:00) Guyana Time"
        },
        {
           "timeZone":"America/Asuncion",
           "ariaLabel":"(GMT minus 04:00)Paraguay Time",
           "formattedText":"(GMT-04:00) Paraguay Time"
        },
        {
           "timeZone":"America/Caracas",
           "ariaLabel":"(GMT minus 04:00)Venezuela Time",
           "formattedText":"(GMT-04:00) Venezuela Time"
        },
        {
           "timeZone":"America/Argentina/Buenos_Aires",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Buenos Aires",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Buenos Aires"
        },
        {
           "timeZone":"America/Argentina/Catamarca",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Catamarca",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Catamarca"
        },
        {
           "timeZone":"America/Argentina/Cordoba",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Cordoba",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Cordoba"
        },
        {
           "timeZone":"America/Argentina/Jujuy",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Jujuy",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Jujuy"
        },
        {
           "timeZone":"America/Argentina/La_Rioja",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - La Rioja",
           "formattedText":"(GMT-03:00) Argentina Standard Time - La Rioja"
        },
        {
           "timeZone":"America/Argentina/Mendoza",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Mendoza",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Mendoza"
        },
        {
           "timeZone":"America/Argentina/Rio_Gallegos",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Rio Gallegos",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Rio Gallegos"
        },
        {
           "timeZone":"America/Argentina/Salta",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Salta",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Salta"
        },
        {
           "timeZone":"America/Argentina/San_Juan",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - San Juan",
           "formattedText":"(GMT-03:00) Argentina Standard Time - San Juan"
        },
        {
           "timeZone":"America/Argentina/San_Luis",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - San Luis",
           "formattedText":"(GMT-03:00) Argentina Standard Time - San Luis"
        },
        {
           "timeZone":"America/Argentina/Tucuman",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Tucuman",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Tucuman"
        },
        {
           "timeZone":"America/Argentina/Ushuaia",
           "ariaLabel":"(GMT minus 03:00)Argentina Standard Time - Ushuaia",
           "formattedText":"(GMT-03:00) Argentina Standard Time - Ushuaia"
        },
        {
           "timeZone":"Atlantic/Bermuda",
           "ariaLabel":"(GMT minus 03:00)Atlantic Time - Bermuda",
           "formattedText":"(GMT-03:00) Atlantic Time - Bermuda"
        },
        {
           "timeZone":"America/Glace_Bay",
           "ariaLabel":"(GMT minus 03:00)Atlantic Time - Glace Bay",
           "formattedText":"(GMT-03:00) Atlantic Time - Glace Bay"
        },
        {
           "timeZone":"America/Goose_Bay",
           "ariaLabel":"(GMT minus 03:00)Atlantic Time - Goose Bay",
           "formattedText":"(GMT-03:00) Atlantic Time - Goose Bay"
        },
        {
           "timeZone":"America/Halifax",
           "ariaLabel":"(GMT minus 03:00)Atlantic Time - Halifax",
           "formattedText":"(GMT-03:00) Atlantic Time - Halifax"
        },
        {
           "timeZone":"America/Moncton",
           "ariaLabel":"(GMT minus 03:00)Atlantic Time - Moncton",
           "formattedText":"(GMT-03:00) Atlantic Time - Moncton"
        },
        {
           "timeZone":"America/Thule",
           "ariaLabel":"(GMT minus 03:00)Atlantic Time - Thule",
           "formattedText":"(GMT-03:00) Atlantic Time - Thule"
        },
        {
           "timeZone":"America/Araguaina",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Araguaina",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Araguaina"
        },
        {
           "timeZone":"America/Bahia",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Bahia",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Bahia"
        },
        {
           "timeZone":"America/Belem",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Belem",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Belem"
        },
        {
           "timeZone":"America/Fortaleza",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Fortaleza",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Fortaleza"
        },
        {
           "timeZone":"America/Maceio",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Maceio",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Maceio"
        },
        {
           "timeZone":"America/Recife",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Recife",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Recife"
        },
        {
           "timeZone":"America/Santarem",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Santarem",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Santarem"
        },
        {
           "timeZone":"America/Sao_Paulo",
           "ariaLabel":"(GMT minus 03:00)Brasilia Standard Time - Sao Paulo",
           "formattedText":"(GMT-03:00) Brasilia Standard Time - Sao Paulo"
        },
        {
           "timeZone":"Atlantic/Stanley",
           "ariaLabel":"(GMT minus 03:00)Falkland Islands Standard Time",
           "formattedText":"(GMT-03:00) Falkland Islands Standard Time"
        },
        {
           "timeZone":"America/Cayenne",
           "ariaLabel":"(GMT minus 03:00)French Guiana Time",
           "formattedText":"(GMT-03:00) French Guiana Time"
        },
        {
           "timeZone":"Antarctica/Palmer",
           "ariaLabel":"(GMT minus 03:00)Palmer Time",
           "formattedText":"(GMT-03:00) Palmer Time"
        },
        {
           "timeZone":"America/Punta_Arenas",
           "ariaLabel":"(GMT minus 03:00)Punta Arenas Time",
           "formattedText":"(GMT-03:00) Punta Arenas Time"
        },
        {
           "timeZone":"Antarctica/Rothera",
           "ariaLabel":"(GMT minus 03:00)Rothera Time",
           "formattedText":"(GMT-03:00) Rothera Time"
        },
        {
           "timeZone":"America/Paramaribo",
           "ariaLabel":"(GMT minus 03:00)Suriname Time",
           "formattedText":"(GMT-03:00) Suriname Time"
        },
        {
           "timeZone":"America/Montevideo",
           "ariaLabel":"(GMT minus 03:00)Uruguay Standard Time",
           "formattedText":"(GMT-03:00) Uruguay Standard Time"
        },
        {
           "timeZone":"America/St_Johns",
           "ariaLabel":"(GMT minus 02:30)Newfoundland Time",
           "formattedText":"(GMT-02:30) Newfoundland Time"
        },
        {
           "timeZone":"America/Noronha",
           "ariaLabel":"(GMT minus 02:00)Fernando de Noronha Standard Time",
           "formattedText":"(GMT-02:00) Fernando de Noronha Standard Time"
        },
        {
           "timeZone":"Atlantic/South_Georgia",
           "ariaLabel":"(GMT minus 02:00)South Georgia Time",
           "formattedText":"(GMT-02:00) South Georgia Time"
        },
        {
           "timeZone":"America/Miquelon",
           "ariaLabel":"(GMT minus 02:00)St. Pierre & Miquelon Time",
           "formattedText":"(GMT-02:00) St. Pierre & Miquelon Time"
        },
        {
           "timeZone":"America/Nuuk",
           "ariaLabel":"(GMT minus 02:00)West Greenland Time",
           "formattedText":"(GMT-02:00) West Greenland Time"
        },
        {
           "timeZone":"Atlantic/Cape_Verde",
           "ariaLabel":"(GMT minus 01:00)Cape Verde Standard Time",
           "formattedText":"(GMT-01:00) Cape Verde Standard Time"
        },
        {
           "timeZone":"Atlantic/Azores",
           "ariaLabel":"(GMT+00:00) Azores Time",
           "formattedText":"(GMT+00:00) Azores Time"
        },
        {
           "timeZone":"UTC",
           "ariaLabel":"(GMT+00:00) Coordinated Universal Time",
           "formattedText":"(GMT+00:00) Coordinated Universal Time"
        },
        {
           "timeZone":"America/Scoresbysund",
           "ariaLabel":"(GMT+00:00) East Greenland Time",
           "formattedText":"(GMT+00:00) East Greenland Time"
        },
        {
           "timeZone":"Etc/GMT",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time",
           "formattedText":"(GMT+00:00) Greenwich Mean Time"
        },
        {
           "timeZone":"Africa/Abidjan",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time - Abidjan",
           "formattedText":"(GMT+00:00) Greenwich Mean Time - Abidjan"
        },
        {
           "timeZone":"Africa/Bissau",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time - Bissau",
           "formattedText":"(GMT+00:00) Greenwich Mean Time - Bissau"
        },
        {
           "timeZone":"America/Danmarkshavn",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time - Danmarkshavn",
           "formattedText":"(GMT+00:00) Greenwich Mean Time - Danmarkshavn"
        },
        {
           "timeZone":"Africa/Monrovia",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time - Monrovia",
           "formattedText":"(GMT+00:00) Greenwich Mean Time - Monrovia"
        },
        {
           "timeZone":"Atlantic/Reykjavik",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time - Reykjavik",
           "formattedText":"(GMT+00:00) Greenwich Mean Time - Reykjavik"
        },
        {
           "timeZone":"Africa/Sao_Tome",
           "ariaLabel":"(GMT+00:00) Greenwich Mean Time - São Tomé",
           "formattedText":"(GMT+00:00) Greenwich Mean Time - São Tomé"
        },
        {
           "timeZone":"Africa/Algiers",
           "ariaLabel":"(GMT+01:00) Central European Standard Time - Algiers",
           "formattedText":"(GMT+01:00) Central European Standard Time - Algiers"
        },
        {
           "timeZone":"Africa/Tunis",
           "ariaLabel":"(GMT+01:00) Central European Standard Time - Tunis",
           "formattedText":"(GMT+01:00) Central European Standard Time - Tunis"
        },
        {
           "timeZone":"Europe/Dublin",
           "ariaLabel":"(GMT+01:00) Ireland Time",
           "formattedText":"(GMT+01:00) Ireland Time"
        },
        {
           "timeZone":"Africa/Casablanca",
           "ariaLabel":"(GMT+01:00) Morocco Time",
           "formattedText":"(GMT+01:00) Morocco Time"
        },
        {
           "timeZone":"Europe/London",
           "ariaLabel":"(GMT+01:00) United Kingdom Time",
           "formattedText":"(GMT+01:00) United Kingdom Time"
        },
        {
           "timeZone":"Africa/Lagos",
           "ariaLabel":"(GMT+01:00) West Africa Standard Time - Lagos",
           "formattedText":"(GMT+01:00) West Africa Standard Time - Lagos"
        },
        {
           "timeZone":"Africa/Ndjamena",
           "ariaLabel":"(GMT+01:00) West Africa Standard Time - Ndjamena",
           "formattedText":"(GMT+01:00) West Africa Standard Time - Ndjamena"
        },
        {
           "timeZone":"Atlantic/Canary",
           "ariaLabel":"(GMT+01:00) Western European Time - Canary",
           "formattedText":"(GMT+01:00) Western European Time - Canary"
        },
        {
           "timeZone":"Atlantic/Faroe",
           "ariaLabel":"(GMT+01:00) Western European Time - Faroe",
           "formattedText":"(GMT+01:00) Western European Time - Faroe"
        },
        {
           "timeZone":"Europe/Lisbon",
           "ariaLabel":"(GMT+01:00) Western European Time - Lisbon",
           "formattedText":"(GMT+01:00) Western European Time - Lisbon"
        },
        {
           "timeZone":"Atlantic/Madeira",
           "ariaLabel":"(GMT+01:00) Western European Time - Madeira",
           "formattedText":"(GMT+01:00) Western European Time - Madeira"
        },
        {
           "timeZone":"Africa/El_Aaiun",
           "ariaLabel":"(GMT+01:00) Western Sahara Time",
           "formattedText":"(GMT+01:00) Western Sahara Time"
        },
        {
           "timeZone":"Africa/Juba",
           "ariaLabel":"(GMT+02:00) Central Africa Time - Juba",
           "formattedText":"(GMT+02:00) Central Africa Time - Juba"
        },
        {
           "timeZone":"Africa/Khartoum",
           "ariaLabel":"(GMT+02:00) Central Africa Time - Khartoum",
           "formattedText":"(GMT+02:00) Central Africa Time - Khartoum"
        },
        {
           "timeZone":"Africa/Maputo",
           "ariaLabel":"(GMT+02:00) Central Africa Time - Maputo",
           "formattedText":"(GMT+02:00) Central Africa Time - Maputo"
        },
        {
           "timeZone":"Africa/Windhoek",
           "ariaLabel":"(GMT+02:00) Central Africa Time - Windhoek",
           "formattedText":"(GMT+02:00) Central Africa Time - Windhoek"
        },
        {
           "timeZone":"Europe/Amsterdam",
           "ariaLabel":"(GMT+02:00) Central European Time - Amsterdam",
           "formattedText":"(GMT+02:00) Central European Time - Amsterdam"
        },
        {
           "timeZone":"Europe/Andorra",
           "ariaLabel":"(GMT+02:00) Central European Time - Andorra",
           "formattedText":"(GMT+02:00) Central European Time - Andorra"
        },
        {
           "timeZone":"Europe/Belgrade",
           "ariaLabel":"(GMT+02:00) Central European Time - Belgrade",
           "formattedText":"(GMT+02:00) Central European Time - Belgrade"
        },
        {
           "timeZone":"Europe/Berlin",
           "ariaLabel":"(GMT+02:00) Central European Time - Berlin",
           "formattedText":"(GMT+02:00) Central European Time - Berlin"
        },
        {
           "timeZone":"Europe/Brussels",
           "ariaLabel":"(GMT+02:00) Central European Time - Brussels",
           "formattedText":"(GMT+02:00) Central European Time - Brussels"
        },
        {
           "timeZone":"Europe/Budapest",
           "ariaLabel":"(GMT+02:00) Central European Time - Budapest",
           "formattedText":"(GMT+02:00) Central European Time - Budapest"
        },
        {
           "timeZone":"Africa/Ceuta",
           "ariaLabel":"(GMT+02:00) Central European Time - Ceuta",
           "formattedText":"(GMT+02:00) Central European Time - Ceuta"
        },
        {
           "timeZone":"Europe/Copenhagen",
           "ariaLabel":"(GMT+02:00) Central European Time - Copenhagen",
           "formattedText":"(GMT+02:00) Central European Time - Copenhagen"
        },
        {
           "timeZone":"Europe/Gibraltar",
           "ariaLabel":"(GMT+02:00) Central European Time - Gibraltar",
           "formattedText":"(GMT+02:00) Central European Time - Gibraltar"
        },
        {
           "timeZone":"Europe/Luxembourg",
           "ariaLabel":"(GMT+02:00) Central European Time - Luxembourg",
           "formattedText":"(GMT+02:00) Central European Time - Luxembourg"
        },
        {
           "timeZone":"Europe/Madrid",
           "ariaLabel":"(GMT+02:00) Central European Time - Madrid",
           "formattedText":"(GMT+02:00) Central European Time - Madrid"
        },
        {
           "timeZone":"Europe/Malta",
           "ariaLabel":"(GMT+02:00) Central European Time - Malta",
           "formattedText":"(GMT+02:00) Central European Time - Malta"
        },
        {
           "timeZone":"Europe/Monaco",
           "ariaLabel":"(GMT+02:00) Central European Time - Monaco",
           "formattedText":"(GMT+02:00) Central European Time - Monaco"
        },
        {
           "timeZone":"Europe/Oslo",
           "ariaLabel":"(GMT+02:00) Central European Time - Oslo",
           "formattedText":"(GMT+02:00) Central European Time - Oslo"
        },
        {
           "timeZone":"Europe/Paris",
           "ariaLabel":"(GMT+02:00) Central European Time - Paris",
           "formattedText":"(GMT+02:00) Central European Time - Paris"
        },
        {
           "timeZone":"Europe/Prague",
           "ariaLabel":"(GMT+02:00) Central European Time - Prague",
           "formattedText":"(GMT+02:00) Central European Time - Prague"
        },
        {
           "timeZone":"Europe/Rome",
           "ariaLabel":"(GMT+02:00) Central European Time - Rome",
           "formattedText":"(GMT+02:00) Central European Time - Rome"
        },
        {
           "timeZone":"Europe/Stockholm",
           "ariaLabel":"(GMT+02:00) Central European Time - Stockholm",
           "formattedText":"(GMT+02:00) Central European Time - Stockholm"
        },
        {
           "timeZone":"Europe/Tirane",
           "ariaLabel":"(GMT+02:00) Central European Time - Tirane",
           "formattedText":"(GMT+02:00) Central European Time - Tirane"
        },
        {
           "timeZone":"Europe/Vienna",
           "ariaLabel":"(GMT+02:00) Central European Time - Vienna",
           "formattedText":"(GMT+02:00) Central European Time - Vienna"
        },
        {
           "timeZone":"Europe/Warsaw",
           "ariaLabel":"(GMT+02:00) Central European Time - Warsaw",
           "formattedText":"(GMT+02:00) Central European Time - Warsaw"
        },
        {
           "timeZone":"Europe/Zurich",
           "ariaLabel":"(GMT+02:00) Central European Time - Zurich",
           "formattedText":"(GMT+02:00) Central European Time - Zurich"
        },
        {
           "timeZone":"Europe/Kaliningrad",
           "ariaLabel":"(GMT+02:00) Eastern European Standard Time - Kaliningrad",
           "formattedText":"(GMT+02:00) Eastern European Standard Time - Kaliningrad"
        },
        {
           "timeZone":"Africa/Tripoli",
           "ariaLabel":"(GMT+02:00) Eastern European Standard Time - Tripoli",
           "formattedText":"(GMT+02:00) Eastern European Standard Time - Tripoli"
        },
        {
           "timeZone":"Africa/Johannesburg",
           "ariaLabel":"(GMT+02:00) South Africa Standard Time",
           "formattedText":"(GMT+02:00) South Africa Standard Time"
        },
        {
           "timeZone":"Antarctica/Troll",
           "ariaLabel":"(GMT+02:00) Troll Time",
           "formattedText":"(GMT+02:00) Troll Time"
        },
        {
           "timeZone":"Asia/Baghdad",
           "ariaLabel":"(GMT+03:00) Arabian Standard Time - Baghdad",
           "formattedText":"(GMT+03:00) Arabian Standard Time - Baghdad"
        },
        {
           "timeZone":"Asia/Qatar",
           "ariaLabel":"(GMT+03:00) Arabian Standard Time - Qatar",
           "formattedText":"(GMT+03:00) Arabian Standard Time - Qatar"
        },
        {
           "timeZone":"Asia/Riyadh",
           "ariaLabel":"(GMT+03:00) Arabian Standard Time - Riyadh",
           "formattedText":"(GMT+03:00) Arabian Standard Time - Riyadh"
        },
        {
           "timeZone":"Africa/Nairobi",
           "ariaLabel":"(GMT+03:00) East Africa Time",
           "formattedText":"(GMT+03:00) East Africa Time"
        },
        {
           "timeZone":"Europe/Athens",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Athens",
           "formattedText":"(GMT+03:00) Eastern European Time - Athens"
        },
        {
           "timeZone":"Asia/Beirut",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Beirut",
           "formattedText":"(GMT+03:00) Eastern European Time - Beirut"
        },
        {
           "timeZone":"Europe/Bucharest",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Bucharest",
           "formattedText":"(GMT+03:00) Eastern European Time - Bucharest"
        },
        {
           "timeZone":"Africa/Cairo",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Cairo",
           "formattedText":"(GMT+03:00) Eastern European Time - Cairo"
        },
        {
           "timeZone":"Europe/Chisinau",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Chisinau",
           "formattedText":"(GMT+03:00) Eastern European Time - Chisinau"
        },
        {
           "timeZone":"Asia/Gaza",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Gaza",
           "formattedText":"(GMT+03:00) Eastern European Time - Gaza"
        },
        {
           "timeZone":"Asia/Hebron",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Hebron",
           "formattedText":"(GMT+03:00) Eastern European Time - Hebron"
        },
        {
           "timeZone":"Europe/Helsinki",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Helsinki",
           "formattedText":"(GMT+03:00) Eastern European Time - Helsinki"
        },
        {
           "timeZone":"Europe/Kiev",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Kyiv",
           "formattedText":"(GMT+03:00) Eastern European Time - Kyiv"
        },
        {
           "timeZone":"Asia/Nicosia",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Nicosia",
           "formattedText":"(GMT+03:00) Eastern European Time - Nicosia"
        },
        {
           "timeZone":"Europe/Riga",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Riga",
           "formattedText":"(GMT+03:00) Eastern European Time - Riga"
        },
        {
           "timeZone":"Europe/Sofia",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Sofia",
           "formattedText":"(GMT+03:00) Eastern European Time - Sofia"
        },
        {
           "timeZone":"Europe/Tallinn",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Tallinn",
           "formattedText":"(GMT+03:00) Eastern European Time - Tallinn"
        },
        {
           "timeZone":"Europe/Uzhgorod",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Uzhhorod",
           "formattedText":"(GMT+03:00) Eastern European Time - Uzhhorod"
        },
        {
           "timeZone":"Europe/Vilnius",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Vilnius",
           "formattedText":"(GMT+03:00) Eastern European Time - Vilnius"
        },
        {
           "timeZone":"Europe/Zaporozhye",
           "ariaLabel":"(GMT+03:00) Eastern European Time - Zaporozhye",
           "formattedText":"(GMT+03:00) Eastern European Time - Zaporozhye"
        },
        {
           "timeZone":"Asia/Famagusta",
           "ariaLabel":"(GMT+03:00) Famagusta Time",
           "formattedText":"(GMT+03:00) Famagusta Time"
        },
        {
           "timeZone":"Asia/Jerusalem",
           "ariaLabel":"(GMT+03:00) Israel Time",
           "formattedText":"(GMT+03:00) Israel Time"
        },
        {
           "timeZone":"Asia/Amman",
           "ariaLabel":"(GMT+03:00) Jordan Time",
           "formattedText":"(GMT+03:00) Jordan Time"
        },
        {
           "timeZone":"Europe/Kirov",
           "ariaLabel":"(GMT+03:00) Kirov Time",
           "formattedText":"(GMT+03:00) Kirov Time"
        },
        {
           "timeZone":"Europe/Minsk",
           "ariaLabel":"(GMT+03:00) Moscow Standard Time - Minsk",
           "formattedText":"(GMT+03:00) Moscow Standard Time - Minsk"
        },
        {
           "timeZone":"Europe/Moscow",
           "ariaLabel":"(GMT+03:00) Moscow Standard Time - Moscow",
           "formattedText":"(GMT+03:00) Moscow Standard Time - Moscow"
        },
        {
           "timeZone":"Europe/Simferopol",
           "ariaLabel":"(GMT+03:00) Moscow Standard Time - Simferopol",
           "formattedText":"(GMT+03:00) Moscow Standard Time - Simferopol"
        },
        {
           "timeZone":"Asia/Damascus",
           "ariaLabel":"(GMT+03:00) Syria Time",
           "formattedText":"(GMT+03:00) Syria Time"
        },
        {
           "timeZone":"Europe/Istanbul",
           "ariaLabel":"(GMT+03:00) Türkiye Time",
           "formattedText":"(GMT+03:00) Türkiye Time"
        },
        {
           "timeZone":"Europe/Volgograd",
           "ariaLabel":"(GMT+03:00) Volgograd Standard Time",
           "formattedText":"(GMT+03:00) Volgograd Standard Time"
        },
        {
           "timeZone":"Asia/Tehran",
           "ariaLabel":"(GMT+03:30) Iran Standard Time",
           "formattedText":"(GMT+03:30) Iran Standard Time"
        },
        {
           "timeZone":"Asia/Yerevan",
           "ariaLabel":"(GMT+04:00) Armenia Standard Time",
           "formattedText":"(GMT+04:00) Armenia Standard Time"
        },
        {
           "timeZone":"Europe/Astrakhan",
           "ariaLabel":"(GMT+04:00) Astrakhan Time",
           "formattedText":"(GMT+04:00) Astrakhan Time"
        },
        {
           "timeZone":"Asia/Baku",
           "ariaLabel":"(GMT+04:00) Azerbaijan Standard Time",
           "formattedText":"(GMT+04:00) Azerbaijan Standard Time"
        },
        {
           "timeZone":"Asia/Tbilisi",
           "ariaLabel":"(GMT+04:00) Georgia Standard Time",
           "formattedText":"(GMT+04:00) Georgia Standard Time"
        },
        {
           "timeZone":"Asia/Dubai",
           "ariaLabel":"(GMT+04:00) Gulf Standard Time",
           "formattedText":"(GMT+04:00) Gulf Standard Time"
        },
        {
           "timeZone":"Indian/Mauritius",
           "ariaLabel":"(GMT+04:00) Mauritius Standard Time",
           "formattedText":"(GMT+04:00) Mauritius Standard Time"
        },
        {
           "timeZone":"Indian/Reunion",
           "ariaLabel":"(GMT+04:00) Réunion Time",
           "formattedText":"(GMT+04:00) Réunion Time"
        },
        {
           "timeZone":"Europe/Samara",
           "ariaLabel":"(GMT+04:00) Samara Standard Time",
           "formattedText":"(GMT+04:00) Samara Standard Time"
        },
        {
           "timeZone":"Europe/Saratov",
           "ariaLabel":"(GMT+04:00) Saratov Time",
           "formattedText":"(GMT+04:00) Saratov Time"
        },
        {
           "timeZone":"Indian/Mahe",
           "ariaLabel":"(GMT+04:00) Seychelles Time",
           "formattedText":"(GMT+04:00) Seychelles Time"
        },
        {
           "timeZone":"Europe/Ulyanovsk",
           "ariaLabel":"(GMT+04:00) Ulyanovsk Time",
           "formattedText":"(GMT+04:00) Ulyanovsk Time"
        },
        {
           "timeZone":"Asia/Kabul",
           "ariaLabel":"(GMT+04:30) Afghanistan Time",
           "formattedText":"(GMT+04:30) Afghanistan Time"
        },
        {
           "timeZone":"Indian/Kerguelen",
           "ariaLabel":"(GMT+05:00) French Southern & Antarctic Time",
           "formattedText":"(GMT+05:00) French Southern & Antarctic Time"
        },
        {
           "timeZone":"Indian/Maldives",
           "ariaLabel":"(GMT+05:00) Maldives Time",
           "formattedText":"(GMT+05:00) Maldives Time"
        },
        {
           "timeZone":"Antarctica/Mawson",
           "ariaLabel":"(GMT+05:00) Mawson Time",
           "formattedText":"(GMT+05:00) Mawson Time"
        },
        {
           "timeZone":"Asia/Karachi",
           "ariaLabel":"(GMT+05:00) Pakistan Standard Time",
           "formattedText":"(GMT+05:00) Pakistan Standard Time"
        },
        {
           "timeZone":"Asia/Dushanbe",
           "ariaLabel":"(GMT+05:00) Tajikistan Time",
           "formattedText":"(GMT+05:00) Tajikistan Time"
        },
        {
           "timeZone":"Asia/Ashgabat",
           "ariaLabel":"(GMT+05:00) Turkmenistan Standard Time",
           "formattedText":"(GMT+05:00) Turkmenistan Standard Time"
        },
        {
           "timeZone":"Asia/Samarkand",
           "ariaLabel":"(GMT+05:00) Uzbekistan Standard Time - Samarkand",
           "formattedText":"(GMT+05:00) Uzbekistan Standard Time - Samarkand"
        },
        {
           "timeZone":"Asia/Tashkent",
           "ariaLabel":"(GMT+05:00) Uzbekistan Standard Time - Tashkent",
           "formattedText":"(GMT+05:00) Uzbekistan Standard Time - Tashkent"
        },
        {
           "timeZone":"Asia/Aqtau",
           "ariaLabel":"(GMT+05:00) West Kazakhstan Time - Aqtau",
           "formattedText":"(GMT+05:00) West Kazakhstan Time - Aqtau"
        },
        {
           "timeZone":"Asia/Aqtobe",
           "ariaLabel":"(GMT+05:00) West Kazakhstan Time - Aqtobe",
           "formattedText":"(GMT+05:00) West Kazakhstan Time - Aqtobe"
        },
        {
           "timeZone":"Asia/Atyrau",
           "ariaLabel":"(GMT+05:00) West Kazakhstan Time - Atyrau",
           "formattedText":"(GMT+05:00) West Kazakhstan Time - Atyrau"
        },
        {
           "timeZone":"Asia/Oral",
           "ariaLabel":"(GMT+05:00) West Kazakhstan Time - Oral",
           "formattedText":"(GMT+05:00) West Kazakhstan Time - Oral"
        },
        {
           "timeZone":"Asia/Qyzylorda",
           "ariaLabel":"(GMT+05:00) West Kazakhstan Time - Qyzylorda",
           "formattedText":"(GMT+05:00) West Kazakhstan Time - Qyzylorda"
        },
        {
           "timeZone":"Asia/Yekaterinburg",
           "ariaLabel":"(GMT+05:00) Yekaterinburg Standard Time",
           "formattedText":"(GMT+05:00) Yekaterinburg Standard Time"
        },
        {
           "timeZone":"Asia/Colombo",
           "ariaLabel":"(GMT+05:30) India Standard Time - Colombo",
           "formattedText":"(GMT+05:30) India Standard Time - Colombo"
        },
        {
           "timeZone":"Asia/Kolkata",
           "ariaLabel":"(GMT+05:30) India Standard Time - Kolkata",
           "formattedText":"(GMT+05:30) India Standard Time - Kolkata"
        },
        {
           "timeZone":"Asia/Kathmandu",
           "ariaLabel":"(GMT+05:45) Nepal Time",
           "formattedText":"(GMT+05:45) Nepal Time"
        },
        {
           "timeZone":"Asia/Dhaka",
           "ariaLabel":"(GMT+06:00) Bangladesh Standard Time",
           "formattedText":"(GMT+06:00) Bangladesh Standard Time"
        },
        {
           "timeZone":"Asia/Thimphu",
           "ariaLabel":"(GMT+06:00) Bhutan Time",
           "formattedText":"(GMT+06:00) Bhutan Time"
        },
        {
           "timeZone":"Asia/Almaty",
           "ariaLabel":"(GMT+06:00) East Kazakhstan Time - Almaty",
           "formattedText":"(GMT+06:00) East Kazakhstan Time - Almaty"
        },
        {
           "timeZone":"Asia/Qostanay",
           "ariaLabel":"(GMT+06:00) East Kazakhstan Time - Kostanay",
           "formattedText":"(GMT+06:00) East Kazakhstan Time - Kostanay"
        },
        {
           "timeZone":"Indian/Chagos",
           "ariaLabel":"(GMT+06:00) Indian Ocean Time",
           "formattedText":"(GMT+06:00) Indian Ocean Time"
        },
        {
           "timeZone":"Asia/Bishkek",
           "ariaLabel":"(GMT+06:00) Kyrgyzstan Time",
           "formattedText":"(GMT+06:00) Kyrgyzstan Time"
        },
        {
           "timeZone":"Asia/Omsk",
           "ariaLabel":"(GMT+06:00) Omsk Standard Time",
           "formattedText":"(GMT+06:00) Omsk Standard Time"
        },
        {
           "timeZone":"Asia/Urumqi",
           "ariaLabel":"(GMT+06:00) Urumqi Time",
           "formattedText":"(GMT+06:00) Urumqi Time"
        },
        {
           "timeZone":"Antarctica/Vostok",
           "ariaLabel":"(GMT+06:00) Vostok Time",
           "formattedText":"(GMT+06:00) Vostok Time"
        },
        {
           "timeZone":"Indian/Cocos",
           "ariaLabel":"(GMT+06:30) Cocos Islands Time",
           "formattedText":"(GMT+06:30) Cocos Islands Time"
        },
        {
           "timeZone":"Asia/Yangon",
           "ariaLabel":"(GMT+06:30) Myanmar Time",
           "formattedText":"(GMT+06:30) Myanmar Time"
        },
        {
           "timeZone":"Asia/Barnaul",
           "ariaLabel":"(GMT+07:00) Barnaul Time",
           "formattedText":"(GMT+07:00) Barnaul Time"
        },
        {
           "timeZone":"Indian/Christmas",
           "ariaLabel":"(GMT+07:00) Christmas Island Time",
           "formattedText":"(GMT+07:00) Christmas Island Time"
        },
        {
           "timeZone":"Antarctica/Davis",
           "ariaLabel":"(GMT+07:00) Davis Time",
           "formattedText":"(GMT+07:00) Davis Time"
        },
        {
           "timeZone":"Asia/Hovd",
           "ariaLabel":"(GMT+07:00) Hovd Standard Time",
           "formattedText":"(GMT+07:00) Hovd Standard Time"
        },
        {
           "timeZone":"Asia/Bangkok",
           "ariaLabel":"(GMT+07:00) Indochina Time - Bangkok",
           "formattedText":"(GMT+07:00) Indochina Time - Bangkok"
        },
        {
           "timeZone":"Asia/Ho_Chi_Minh",
           "ariaLabel":"(GMT+07:00) Indochina Time - Ho Chi Minh City",
           "formattedText":"(GMT+07:00) Indochina Time - Ho Chi Minh City"
        },
        {
           "timeZone":"Asia/Krasnoyarsk",
           "ariaLabel":"(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk",
           "formattedText":"(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk"
        },
        {
           "timeZone":"Asia/Novokuznetsk",
           "ariaLabel":"(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk",
           "formattedText":"(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk"
        },
        {
           "timeZone":"Asia/Novosibirsk",
           "ariaLabel":"(GMT+07:00) Novosibirsk Standard Time",
           "formattedText":"(GMT+07:00) Novosibirsk Standard Time"
        },
        {
           "timeZone":"Asia/Tomsk",
           "ariaLabel":"(GMT+07:00) Tomsk Time",
           "formattedText":"(GMT+07:00) Tomsk Time"
        },
        {
           "timeZone":"Asia/Jakarta",
           "ariaLabel":"(GMT+07:00) Western Indonesia Time - Jakarta",
           "formattedText":"(GMT+07:00) Western Indonesia Time - Jakarta"
        },
        {
           "timeZone":"Asia/Pontianak",
           "ariaLabel":"(GMT+07:00) Western Indonesia Time - Pontianak",
           "formattedText":"(GMT+07:00) Western Indonesia Time - Pontianak"
        },
        {
           "timeZone":"Australia/Perth",
           "ariaLabel":"(GMT+08:00) Australian Western Standard Time",
           "formattedText":"(GMT+08:00) Australian Western Standard Time"
        },
        {
           "timeZone":"Asia/Brunei",
           "ariaLabel":"(GMT+08:00) Brunei Darussalam Time",
           "formattedText":"(GMT+08:00) Brunei Darussalam Time"
        },
        {
           "timeZone":"Asia/Makassar",
           "ariaLabel":"(GMT+08:00) Central Indonesia Time",
           "formattedText":"(GMT+08:00) Central Indonesia Time"
        },
        {
           "timeZone":"Asia/Macau",
           "ariaLabel":"(GMT+08:00) China Standard Time - Macao",
           "formattedText":"(GMT+08:00) China Standard Time - Macao"
        },
        {
           "timeZone":"Asia/Shanghai",
           "ariaLabel":"(GMT+08:00) China Standard Time - Shanghai",
           "formattedText":"(GMT+08:00) China Standard Time - Shanghai"
        },
        {
           "timeZone":"Asia/Hong_Kong",
           "ariaLabel":"(GMT+08:00) Hong Kong Standard Time",
           "formattedText":"(GMT+08:00) Hong Kong Standard Time"
        },
        {
           "timeZone":"Asia/Irkutsk",
           "ariaLabel":"(GMT+08:00) Irkutsk Standard Time",
           "formattedText":"(GMT+08:00) Irkutsk Standard Time"
        },
        {
           "timeZone":"Asia/Kuala_Lumpur",
           "ariaLabel":"(GMT+08:00) Malaysia Time - Kuala Lumpur",
           "formattedText":"(GMT+08:00) Malaysia Time - Kuala Lumpur"
        },
        {
           "timeZone":"Asia/Kuching",
           "ariaLabel":"(GMT+08:00) Malaysia Time - Kuching",
           "formattedText":"(GMT+08:00) Malaysia Time - Kuching"
        },
        {
           "timeZone":"Asia/Manila",
           "ariaLabel":"(GMT+08:00) Philippine Standard Time",
           "formattedText":"(GMT+08:00) Philippine Standard Time"
        },
        {
           "timeZone":"Asia/Singapore",
           "ariaLabel":"(GMT+08:00) Singapore Standard Time",
           "formattedText":"(GMT+08:00) Singapore Standard Time"
        },
        {
           "timeZone":"Asia/Taipei",
           "ariaLabel":"(GMT+08:00) Taipei Standard Time",
           "formattedText":"(GMT+08:00) Taipei Standard Time"
        },
        {
           "timeZone":"Asia/Choibalsan",
           "ariaLabel":"(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan",
           "formattedText":"(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan"
        },
        {
           "timeZone":"Asia/Ulaanbaatar",
           "ariaLabel":"(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar",
           "formattedText":"(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar"
        },
        {
           "timeZone":"Australia/Eucla",
           "ariaLabel":"(GMT+08:45) Australian Central Western Standard Time",
           "formattedText":"(GMT+08:45) Australian Central Western Standard Time"
        },
        {
           "timeZone":"Asia/Dili",
           "ariaLabel":"(GMT+09:00) East Timor Time",
           "formattedText":"(GMT+09:00) East Timor Time"
        },
        {
           "timeZone":"Asia/Jayapura",
           "ariaLabel":"(GMT+09:00) Eastern Indonesia Time",
           "formattedText":"(GMT+09:00) Eastern Indonesia Time"
        },
        {
           "timeZone":"Asia/Tokyo",
           "ariaLabel":"(GMT+09:00) Japan Standard Time",
           "formattedText":"(GMT+09:00) Japan Standard Time"
        },
        {
           "timeZone":"Asia/Pyongyang",
           "ariaLabel":"(GMT+09:00) Korean Standard Time - Pyongyang",
           "formattedText":"(GMT+09:00) Korean Standard Time - Pyongyang"
        },
        {
           "timeZone":"Asia/Seoul",
           "ariaLabel":"(GMT+09:00) Korean Standard Time - Seoul",
           "formattedText":"(GMT+09:00) Korean Standard Time - Seoul"
        },
        {
           "timeZone":"Pacific/Palau",
           "ariaLabel":"(GMT+09:00) Palau Time",
           "formattedText":"(GMT+09:00) Palau Time"
        },
        {
           "timeZone":"Asia/Chita",
           "ariaLabel":"(GMT+09:00) Yakutsk Standard Time - Chita",
           "formattedText":"(GMT+09:00) Yakutsk Standard Time - Chita"
        },
        {
           "timeZone":"Asia/Khandyga",
           "ariaLabel":"(GMT+09:00) Yakutsk Standard Time - Khandyga",
           "formattedText":"(GMT+09:00) Yakutsk Standard Time - Khandyga"
        },
        {
           "timeZone":"Asia/Yakutsk",
           "ariaLabel":"(GMT+09:00) Yakutsk Standard Time - Yakutsk",
           "formattedText":"(GMT+09:00) Yakutsk Standard Time - Yakutsk"
        },
        {
           "timeZone":"Australia/Darwin",
           "ariaLabel":"(GMT+09:30) Australian Central Standard Time",
           "formattedText":"(GMT+09:30) Australian Central Standard Time"
        },
        {
           "timeZone":"Australia/Adelaide",
           "ariaLabel":"(GMT+09:30) Central Australia Time - Adelaide",
           "formattedText":"(GMT+09:30) Central Australia Time - Adelaide"
        },
        {
           "timeZone":"Australia/Broken_Hill",
           "ariaLabel":"(GMT+09:30) Central Australia Time - Broken Hill",
           "formattedText":"(GMT+09:30) Central Australia Time - Broken Hill"
        },
        {
           "timeZone":"Australia/Brisbane",
           "ariaLabel":"(GMT+10:00) Australian Eastern Standard Time - Brisbane",
           "formattedText":"(GMT+10:00) Australian Eastern Standard Time - Brisbane"
        },
        {
           "timeZone":"Australia/Lindeman",
           "ariaLabel":"(GMT+10:00) Australian Eastern Standard Time - Lindeman",
           "formattedText":"(GMT+10:00) Australian Eastern Standard Time - Lindeman"
        },
        {
           "timeZone":"Pacific/Guam",
           "ariaLabel":"(GMT+10:00) Chamorro Standard Time",
           "formattedText":"(GMT+10:00) Chamorro Standard Time"
        },
        {
           "timeZone":"Pacific/Chuuk",
           "ariaLabel":"(GMT+10:00) Chuuk Time",
           "formattedText":"(GMT+10:00) Chuuk Time"
        },
        {
           "timeZone":"Australia/Hobart",
           "ariaLabel":"(GMT+10:00) Eastern Australia Time - Hobart",
           "formattedText":"(GMT+10:00) Eastern Australia Time - Hobart"
        },
        {
           "timeZone":"Antarctica/Macquarie",
           "ariaLabel":"(GMT+10:00) Eastern Australia Time - Macquarie",
           "formattedText":"(GMT+10:00) Eastern Australia Time - Macquarie"
        },
        {
           "timeZone":"Australia/Melbourne",
           "ariaLabel":"(GMT+10:00) Eastern Australia Time - Melbourne",
           "formattedText":"(GMT+10:00) Eastern Australia Time - Melbourne"
        },
        {
           "timeZone":"Australia/Sydney",
           "ariaLabel":"(GMT+10:00) Eastern Australia Time - Sydney",
           "formattedText":"(GMT+10:00) Eastern Australia Time - Sydney"
        },
        {
           "timeZone":"Pacific/Port_Moresby",
           "ariaLabel":"(GMT+10:00) Papua New Guinea Time",
           "formattedText":"(GMT+10:00) Papua New Guinea Time"
        },
        {
           "timeZone":"Asia/Ust-Nera",
           "ariaLabel":"(GMT+10:00) Vladivostok Standard Time - Ust-Nera",
           "formattedText":"(GMT+10:00) Vladivostok Standard Time - Ust-Nera"
        },
        {
           "timeZone":"Asia/Vladivostok",
           "ariaLabel":"(GMT+10:00) Vladivostok Standard Time - Vladivostok",
           "formattedText":"(GMT+10:00) Vladivostok Standard Time - Vladivostok"
        },
        {
           "timeZone":"Australia/Lord_Howe",
           "ariaLabel":"(GMT+10:30) Lord Howe Time",
           "formattedText":"(GMT+10:30) Lord Howe Time"
        },
        {
           "timeZone":"Pacific/Bougainville",
           "ariaLabel":"(GMT+11:00) Bougainville Time",
           "formattedText":"(GMT+11:00) Bougainville Time"
        },
        {
           "timeZone":"Antarctica/Casey",
           "ariaLabel":"(GMT+11:00) Casey Time",
           "formattedText":"(GMT+11:00) Casey Time"
        },
        {
           "timeZone":"Pacific/Kosrae",
           "ariaLabel":"(GMT+11:00) Kosrae Time",
           "formattedText":"(GMT+11:00) Kosrae Time"
        },
        {
           "timeZone":"Asia/Magadan",
           "ariaLabel":"(GMT+11:00) Magadan Standard Time",
           "formattedText":"(GMT+11:00) Magadan Standard Time"
        },
        {
           "timeZone":"Pacific/Noumea",
           "ariaLabel":"(GMT+11:00) New Caledonia Standard Time",
           "formattedText":"(GMT+11:00) New Caledonia Standard Time"
        },
        {
           "timeZone":"Pacific/Norfolk",
           "ariaLabel":"(GMT+11:00) Norfolk Island Time",
           "formattedText":"(GMT+11:00) Norfolk Island Time"
        },
        {
           "timeZone":"Pacific/Pohnpei",
           "ariaLabel":"(GMT+11:00) Ponape Time",
           "formattedText":"(GMT+11:00) Ponape Time"
        },
        {
           "timeZone":"Asia/Sakhalin",
           "ariaLabel":"(GMT+11:00) Sakhalin Standard Time",
           "formattedText":"(GMT+11:00) Sakhalin Standard Time"
        },
        {
           "timeZone":"Pacific/Guadalcanal",
           "ariaLabel":"(GMT+11:00) Solomon Islands Time",
           "formattedText":"(GMT+11:00) Solomon Islands Time"
        },
        {
           "timeZone":"Asia/Srednekolymsk",
           "ariaLabel":"(GMT+11:00) Srednekolymsk Time",
           "formattedText":"(GMT+11:00) Srednekolymsk Time"
        },
        {
           "timeZone":"Pacific/Efate",
           "ariaLabel":"(GMT+11:00) Vanuatu Standard Time",
           "formattedText":"(GMT+11:00) Vanuatu Standard Time"
        },
        {
           "timeZone":"Asia/Anadyr",
           "ariaLabel":"(GMT+12:00) Anadyr Standard Time",
           "formattedText":"(GMT+12:00) Anadyr Standard Time"
        },
        {
           "timeZone":"Pacific/Fiji",
           "ariaLabel":"(GMT+12:00) Fiji Standard Time",
           "formattedText":"(GMT+12:00) Fiji Standard Time"
        },
        {
           "timeZone":"Pacific/Tarawa",
           "ariaLabel":"(GMT+12:00) Gilbert Islands Time",
           "formattedText":"(GMT+12:00) Gilbert Islands Time"
        },
        {
           "timeZone":"Pacific/Kwajalein",
           "ariaLabel":"(GMT+12:00) Marshall Islands Time - Kwajalein",
           "formattedText":"(GMT+12:00) Marshall Islands Time - Kwajalein"
        },
        {
           "timeZone":"Pacific/Majuro",
           "ariaLabel":"(GMT+12:00) Marshall Islands Time - Majuro",
           "formattedText":"(GMT+12:00) Marshall Islands Time - Majuro"
        },
        {
           "timeZone":"Pacific/Nauru",
           "ariaLabel":"(GMT+12:00) Nauru Time",
           "formattedText":"(GMT+12:00) Nauru Time"
        },
        {
           "timeZone":"Pacific/Auckland",
           "ariaLabel":"(GMT+12:00) New Zealand Time",
           "formattedText":"(GMT+12:00) New Zealand Time"
        },
        {
           "timeZone":"Asia/Kamchatka",
           "ariaLabel":"(GMT+12:00) Petropavlovsk-Kamchatski Standard Time",
           "formattedText":"(GMT+12:00) Petropavlovsk-Kamchatski Standard Time"
        },
        {
           "timeZone":"Pacific/Funafuti",
           "ariaLabel":"(GMT+12:00) Tuvalu Time",
           "formattedText":"(GMT+12:00) Tuvalu Time"
        },
        {
           "timeZone":"Pacific/Wake",
           "ariaLabel":"(GMT+12:00) Wake Island Time",
           "formattedText":"(GMT+12:00) Wake Island Time"
        },
        {
           "timeZone":"Pacific/Wallis",
           "ariaLabel":"(GMT+12:00) Wallis & Futuna Time",
           "formattedText":"(GMT+12:00) Wallis & Futuna Time"
        },
        {
           "timeZone":"Pacific/Chatham",
           "ariaLabel":"(GMT+12:45) Chatham Time",
           "formattedText":"(GMT+12:45) Chatham Time"
        },
        {
           "timeZone":"Pacific/Apia",
           "ariaLabel":"(GMT+13:00) Apia Standard Time",
           "formattedText":"(GMT+13:00) Apia Standard Time"
        },
        {
           "timeZone":"Pacific/Kanton",
           "ariaLabel":"(GMT+13:00) Phoenix Islands Time",
           "formattedText":"(GMT+13:00) Phoenix Islands Time"
        },
        {
           "timeZone":"Pacific/Fakaofo",
           "ariaLabel":"(GMT+13:00) Tokelau Time",
           "formattedText":"(GMT+13:00) Tokelau Time"
        },
        {
           "timeZone":"Pacific/Tongatapu",
           "ariaLabel":"(GMT+13:00) Tonga Standard Time",
           "formattedText":"(GMT+13:00) Tonga Standard Time"
        },
        {
           "timeZone":"Pacific/Kiritimati",
           "ariaLabel":"(GMT+14:00) Line Islands Time",
           "formattedText":"(GMT+14:00) Line Islands Time"
        }
     ]
  };
